// import { Box, Container, Flex, HStack, Text } from '@chakra-ui/react'
// import React from 'react'
// import { AiOutlineFundProjectionScreen, AiOutlineHome, AiOutlineUser } from 'react-icons/ai'
// import { NavLink, useLocation } from 'react-router-dom'

// const ModernNavbar = () => {
//     const location = useLocation()
//     return (
//         <Box
//             top={'60px'}
//             zIndex={'1050 !important'}
//             position={'sticky !important'}
//             backdropFilter='blur(2px)'
//             as='header'
//             // mt={4}
//             bg='rgba(255,255,255,.9)'
//             boxShadow='0px 4px 8px -4px rgba(76,78,100,.38)'
//         >
//             <Container maxW='8xl'>
//                 <HStack className='nav_men' >
//                     <NavLink className='link_text' activeClassName="active" to={'/dashboard'}><Flex alignItems='center'><AiOutlineHome display='inline-block' fill={location.pathname === '/dashboard' ? 'white' : '#00b4b4'} /><Text pl={1} as='span'>Dashboard</Text></Flex></NavLink>
//                     <NavLink className='link_text' activeClassName="active" to={'/projects'}><Flex alignItems='center'><AiOutlineFundProjectionScreen display='inline-block' fill={location.pathname === '/about' ? 'white' : '#00b4b4'} /><Text pl={1} as='span'>Insight</Text></Flex></NavLink>
//                     <NavLink className='link_text' activeClassName="active" to={'/home'}><Flex alignItems='center'><AiOutlineUser display='inline-block' fill={location.pathname === '/home' ? 'white' : '#00b4b4'} /><Text pl={1} as='span'>Employee List</Text></Flex></NavLink>
//                     <NavLink className='link_text' activeClassName="active" to={'/homee'}><Flex alignItems='center'><AiOutlineUser display='inline-block' fill={location.pathname === '/home' ? 'white' : '#00b4b4'} /><Text pl={1} as='span'>Project</Text></Flex></NavLink>
//                 </HStack>
//             </Container>
//         </Box>
//     )
// }

// export default ModernNavbar

import {
    ChevronRightIcon,
    CloseIcon,
    HamburgerIcon
} from '@chakra-ui/icons';
import {
    Box,
    Center,
    Flex,
    Icon,
    IconButton,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
// import logo from '../../assets/logo/logo-white-dgt.svg'
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineApartment, AiOutlineContainer, AiOutlineDesktop, AiOutlineHome } from 'react-icons/ai';
import { BsListTask } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';
import { Verifycontext } from '../../lib/Providers/LoginVeification';
import { NewThemeContext } from '../../lib/Providers/ThemeSwitch';
function ModernNavbar() {

    const GetIcon = (icon) => {
        switch (icon) {
            case 'dashboardIcon':
                return AiOutlineHome
            case 'taskIcon':
                return BsListTask
            case 'insightIcon':
                return AiOutlineDesktop
            case 'EmployeeIcon':
                return AiOutlineApartment
            case 'projectIcon':
                return AiOutlineContainer
            case 'notificationIcon':
                return AiOutlineDesktop
            default:
                return AiOutlineDesktop

        }
    }

    const location = useLocation()
    const { Module, loading } = useContext(Verifycontext);
    const [nav, setNav] = useState([])

    let Nav = []

    useEffect(() => {
        if (Module?.length > 0) {
            const newNav = Module
                .filter(item => item?.read_data)
                .map(item => {
                    const hasMatchingChild = item?.child?.some(child => location.pathname === child.url);
                    const baseItem = {
                        label: item.name,
                        href: item?.url ? item?.url : '#',
                        icon: <Icon as={GetIcon(item?.icon)} fill={location.pathname === item?.url || hasMatchingChild ? 'white' : '#636578'} />, //<AiOutlineDesktop fill={location.pathname === item?.url || hasMatchingChild ? 'white' : '#636578'} />,
                        color: location.pathname === item?.url || hasMatchingChild ? '#00b4b4' : '#636578',
                        bg: location.pathname === item?.url || hasMatchingChild ? '#00b4b4' : 'white',
                    };

                    if (item.child?.length > 0) {
                        // If the item has children, include them in the base item
                        baseItem.children = item.child
                            .filter(item => item?.read_data)
                            .map(child => ({
                                label: child.name,
                                href: child.url,
                            }));
                    }

                    return baseItem;
                })
            setNav(newNav);
        }
    }, [Module, location.pathname])


    const NAV_ITEMS = [
        {
            label: 'Dashboard',
            href: '/dashboard',
            icon: <AiOutlineHome fill={location.pathname === '/dashboard' ? 'white' : '#636578'} />,
            color: location.pathname === '/dashboard' ? '#00b4b4' : '#636578',
            bg: location.pathname === '/dashboard' ? '#00b4b4' : 'white',
        },
        {
            label: 'Insight',
            icon: <AiOutlineDesktop fill={location.pathname === '/client' || location.pathname === '/department' ? 'white' : '#636578'} />,
            color: location.pathname === '/client' || location.pathname === '/department' ? '#00b4b4' : '#636578',
            bg: location.pathname === '/client' || location.pathname === '/department' ? '#00b4b4' : 'white',
            children: [
                {
                    label: 'Client',
                    // subLabel: 'Find your dream design job',
                    href: '/client',
                },
                {
                    label: 'Deopartment',
                    // subLabel: 'An exclusive list for contract work',
                    href: '/department',
                },
            ],
        },
        {
            label: 'Employee',
            icon: <AiOutlineApartment fill={location.pathname === '/role' || location.pathname === '/employee-list' ? 'white' : '#636578'} />,
            color: location.pathname === '/role' || location.pathname === '/employee-list' ? '#00b4b4' : '#636578',
            bg: location.pathname === '/role' || location.pathname === '/employee-list' ? '#00b4b4' : 'white',
            children: [
                {
                    label: 'Role List',
                    // subLabel: 'Find your dream design job',
                    href: '/role',
                },
                {
                    label: 'Employee & Client List',
                    // subLabel: 'An exclusive list for contract work',
                    href: '/employee-list',
                },
            ],
        },
        {
            label: 'Project',
            icon: <AiOutlineContainer fill={location.pathname === '/add-project' || location.pathname === '/projects' ? 'white' : '#636578'} />,
            color: location.pathname === '/add-project' || location.pathname === '/projects' ? '#00b4b4' : '#636578',
            bg: location.pathname === '/add-project' || location.pathname === '/projects' ? '#00b4b4' : 'white',
            children: [
                {
                    label: 'Add Project',
                    // subLabel: 'Find your dream design job',
                    href: '/add-project',
                },
                {
                    label: 'Project list',
                    // subLabel: 'An exclusive list for contract work',
                    href: '/projects',
                },
            ],
        }
    ]
    const { isOpen, onToggle } = useDisclosure()
    const [isScrolled, setIsScrolled] = useState(false);
    const { theme } = useContext(NewThemeContext);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {theme &&
                <Box
                // pos='fixed'
                // top={0}
                // left={0}
                // right={0}
                // zIndex={2}
                // top={'60px'}
                // zIndex={'1050 !important'}
                // position={'sticky !important'}
                // backdropFilter='blur(2px)'
                // as='header'
                //             // mt={4}
                // bg='rgba(255,255,255,.9)'
                // boxShadow='0px 4px 8px -4px rgba(76,78,100,.38)'
                >

                    <Flex
                        className={isScrolled ? 'scrolled' : 'navbar'}
                        color='#00b4b4'
                        // minH={'60px'}
                        align={'center'}>
                        {/* <Container
                            maxW='8xl'> */}
                        <Flex
                            flex={{ base: 1, md: 'auto' }}
                            ml={{ base: -2 }}
                            display={{ base: 'flex', md: 'none' }}>
                            <IconButton
                                onClick={onToggle}
                                icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
                                variant={'ghost'}
                                aria-label={'Toggle Navigation'}
                            />
                        </Flex>

                        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'space-between' }} align='center'>
                            {/* <Text
                            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                            fontFamily={'heading'}
                            color={useColorModeValue('gray.800', 'white')}>
                            <Img width={200} src={logo} alt='CoreTeams' />
                        </Text> */}

                            <Flex display={{ base: 'none', md: 'flex' }} alignItems='center' >
                                <DesktopNav NAV_ITEMS={nav} />
                                {/* <Stack
                                ml={6}
                                flex={{ base: 1, md: 0 }}
                                justify={'flex-end'}
                                direction={'row'}
                                spacing={6}>
                                <Button
                                    as={'a'}
                                    fontSize={'sm'}
                                    fontWeight={600}
                                    variant={'link'}
                                    href={'#'}
                                    py={2}
                                    px={4}
                                    bg={'white'}
                                    color='#65258a'
                                    opacity={0.9}
                                >
                                    Free Check
                                </Button>
                            </Stack> */}
                            </Flex>
                        </Flex>
                        {/* </Container> */}
                    </Flex>
                </Box>
            }
        </>
    )
}
export default React.memo(ModernNavbar);

const DesktopNav = ({ NAV_ITEMS }) => {
    const navigate = useNavigate()
    const linkColor = useColorModeValue('#00b4b4', 'gray.200')
    const linkHoverColor = useColorModeValue('gray.200', 'white')
    const popoverContentBgColor = useColorModeValue('white', 'gray.800')

    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            {/* <NavLink color=''><Flex alignItems='center'>
                                <AiOutlineHome display='inline-block' fill={location.pathname === '/dashboard' ? 'white' : '#00b4b4'} />
                                <Text pl={1} as='span'> {navItem.label}</Text></Flex></NavLink> */}
                            <Box
                                width='100%'
                                display='block'
                                as="Link"
                                p={2}
                                // href={navItem.href}
                                onClick={() => { navigate(navItem.href) }}
                                cursor='pointer'
                                fontSize='small'
                                fontWeight={500}
                                color={navItem.color}
                            // rounded='lg'
                            // color={navItem.color}
                            // bg={navItem.bg}
                            // _hover={{
                            //     textDecoration: 'none',
                            //     color: linkHoverColor,
                            // }}
                            >
                                <Box alignItems={'center'} className='menu-icon-f'>
                                    <Center
                                        margin='auto'
                                        display='flex'
                                        alignItems='center'
                                        width={10}
                                        height={10}
                                        rounded='full'
                                        bg={navItem.bg}
                                        color={navItem.color}
                                    >{navItem.icon}</Center>
                                    <Box as='span' >{navItem.label}</Box>
                                </Box>
                            </Box>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                py={4}
                                px={2}
                                rounded={'xl'}
                                minW='full'>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    )
}

const DesktopSubNav = ({ label, href, subLabel }) => {
    const navigate = useNavigate()
    return (
        <Box
            as="a"
            // href={href}
            onClick={() => { navigate(href) }}
            cursor='pointer'
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        fontSize='sm'
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'pink.400' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    {/* <Text fontSize={'sm'}>{subLabel}</Text> */}
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Box>
    )
}



